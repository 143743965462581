.custom-row-selection .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b3d1ca; /* Change this to your desired color */
  border-color: #b3d1ca; /* Change this to your desired color */
}

.custom-row-selection .ant-checkbox-checked:after {
  border-color: #b3d1ca; /* Change this to your desired color */
}

.custom-row-selection .ant-checkbox-checked::before {
  border-color: #b3d1ca; /* Change this to your desired color */
}

.custom-row-selection .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #e6f7e679 !important; /* Optional: Change the background color of selected rows */
}
