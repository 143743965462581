@import "./styles/overviewForm.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-mainBackgrround;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7f0ee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3d1ca;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8ebbb1;
}

/* modal height in mobile screen  */
@media (max-width: 768px) {
  .full-screen-modal .ant-modal-content {
    height: 97vh;
  }
  /* Small screens */
  .full-screen-modal .ant-modal {
    height: 90vh !important;
  }
}

/* PrimaryModal */
.custom-modal .ant-modal-content {
  @apply rounded-3xl; /* Adjust the radius using Tailwind's utility class */
}

/* PrimaryModal */
.custom-modal2 .ant-modal-content {
  @apply max-w-[320px];
  @apply mx-auto;
}

/* PrimaryModal */
.custom-modal3 .ant-modal-content {
  @apply max-w-[350px];
  @apply mx-auto;
}

/* cash-release-modal */
@media (min-width: 768px) {
  .cash-release-modal .ant-modal-content {
    width: 100vh;
    margin-left: -14vh;
  }
}

.custom-modal-payment-recipt .ant-modal-content {
  @apply bg-primaryColor-50; /* Adjust the radius using Tailwind's utility class */
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

input[type="checkbox"].custom-checkbox:checked::after {
  content: "\2713"; /* Unicode for a checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Color of the checkmark */
  font-size: 12px; /* Adjust as needed */
}

@layer utilities {
  .clip-star {
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
  }
}
