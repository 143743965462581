/* styles.css */
.react-calendar {
  width: 100%;
  max-width: 280px;
  min-height: 100%;
}

.react-calendar__navigation {
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.react-calendar__month-view__days__day {
  background: #fff;
  border-radius: 50%;
  color: #042d24;
  font-size: 14px;
  font-weight: 700;
  width: 38px; /* Set a fixed width */
  height: 38px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #71717a;
}

.react-calendar__tile--active {
  background-color: #0a6c55;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px;
}

.react-calendar__month-view__weekdays {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #8ebbb1;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.react-calendar__viewContainer,
.react-calendar__month-view {
  border: none;
}

.react-calendar__navigation__label {
  font-size: 24px;
  font-weight: 700;
  color: #042d24;
  top: 5px;
}
.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.react-calendar__tile {
  width: 25px;
  padding: 2px;
}

/* Media query for screens larger than 1024px */
@media (min-width: 768px) {
  .react-calendar {
    max-width: 400px; /* Adjust the max-width for larger screens */
  }

  .react-calendar__navigation__label {
    font-size: 20px; /* Increase the font size for the month label */
  }

  .react-calendar__tile {
    width: 25px;
    /* Increase the size of each day tile */
  }

  .react-calendar__month-view__days__day {
    font-size: 16px; /* Increase the font size of the day numbers */
  }

  .react-calendar__month-view__weekdays {
    font-size: 10px; /* Increase the font size of the weekdays */
  }
}
